
body {
  @apply font-sans; /* This will apply Montserrat as the default font for the body */
}


/* Slick slider CSS */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;700&display=swap');
